<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Get In Touch</h2>

  <div class="spacer-30"></div>

  <div class="row">
    <div class="col-md-4">
      <!-- contact info -->
      <div class="contact-info">
        <h3 class="wow fadeInUp">Let's talk about everything!</h3>
        <p class="wow fadeInUp">
          Don't like forms? Send me an
          <a href="mailto:info@direct-buy.in">email</a>. 👋
        </p>
      </div>
    </div>

    <div class="col-md-8">
      <!-- Contact Form -->
      <form
        [formGroup]="contactForm"
        (ngSubmit)="onSubmit()"
        autocomplete="off"
      >
        <div class="messages"></div>

        <div class="row">
          <div class="column col-md-6">
            <!-- Name input -->
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                name="InputName"
                formControlName="name"
                id="InputName"
                placeholder="Your name"
                required="required"
                data-error="Name is required."
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-6">
            <!-- Email input -->
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="InputContact"
                name="InputContact"
                formControlName="contact"
                placeholder="Contact Number"
                required="required"
                data-error="Contact is required."
              />
              <div class="help-block with-errors"></div>
            </div>
            <!-- <div class="form-group">
              <input
                type="email"
                class="form-control"
                id="InputEmail"
                name="InputEmail"
                formControlName="email"
                placeholder="Email address"
                required="required"
                data-error="Email is required."
              />
              <div class="help-block with-errors"></div>
            </div> -->
          </div>

          <div class="column col-md-12">
            <!-- Email input -->
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="InputSubject"
                name="InputSubject"
                formControlName="subject"
                placeholder="Subject"
                required="required"
                data-error="Subject is required."
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Message textarea -->
            <div class="form-group">
              <textarea
                name="InputMessage"
                formControlName="message"
                id="InputMessage"
                class="form-control"
                rows="5"
                placeholder="Message"
                required="required"
                data-error="Message is required."
              ></textarea>
              <div class="help-block with-errors"></div>
            </div>
          </div>
        </div>

        <a href="javascript:" class="work-content" data-toggle="modal" (click)="contactmodal.show();reload();" >
        <button
          type="submit"
          name="submit"
          id="submit"
          value="Submit"
          class="btn btn-default"
        >
          Send Message</button
        ></a><!-- Send Button -->
      </form>
      <!-- Contact Form end -->
    </div>
  </div>
</div>


<app-ui-modal #contactmodal dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true" [hidden]="modal">
  <div class="app-modal-body">
    <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
      <a type="button" class="close closeButton" (click)="close()" >&times;</a>
      <h2>Thank you! We have received your request, our team will contact you soon.</h2>
    </div>
  </div>
</app-ui-modal>