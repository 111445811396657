<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Services</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center  shadow-blue ">
        <!-- <img src="assets/images/service-1.svg" alt="UI/UX design" /> -->
        <i class="fas fa-store "></i>
        <h3 class="mb-3 mt-0">Store Configuration</h3>
        <p class="mb-0">Manage your store's configuration, like product, stock, payment gateway, offers, orders.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center shadow-blue ">
        <!-- <img src="assets/images/service-2.svg" alt="UI/UX design" /> -->
        <i class="fab fa-first-order"></i>
        <h3 class="mb-3 mt-0">Order Management</h3>
        <p class="mb-0">Get notified of orders, payments delivered right to your phone.</p>
        <br/>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center  shadow-blue ">
        <!-- <img src="assets/images/service-3.svg" alt="UI/UX design" /> -->
        <i class="fas fa-boxes"></i>
        <h3 class="mb-3 mt-0">Inventory Management</h3>
        <p class="mb-0">Manage stock and products right from the app. You can add, edit and delete products.</p>
      </div>
    </div>

  </div>

  <!-- <div class="mt-5 text-center">
    <p class="mb-0">Looking for a custom job? <a href="javascript:" (click)="scrollTo('contact')">Click here</a> to contact me! 👋</p>
  </div> -->

</div>
