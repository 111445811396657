import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/Model/service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  modal: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private _http: HttpClient,
    private apiService: ServiceService
  ) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      // email: ['', Validators.required],
      contact: ['', Validators.required, Validators.pattern('^[0-9]{10}$')],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
    // if (this.isMobile()) {
    //   window.location.href = "https://m.direct-buy.in";
    // }
  }

  onSubmit() {
    console.log(this.contactForm.value);
    if (this.contactForm.invalid) {
      return;
    }

    this.apiService.contactForm(this.contactForm.value).subscribe((data) => {
      this.ngOnInit();
    });
  }

  reload(){
    setTimeout(()=>{
      window.location.reload();
    }, 2000);
  }
  close() {
    this.modal=true;
    // this.modal=true;
    window.location.reload();
}
}
