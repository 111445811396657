import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
// import { Router } from "@angular/router";
declare let require: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('bounce', [
      transition(
        '* => *',
        useAnimation(bounceIn, {
          // Set the duration to 5seconds and delay to 2seconds
          params: {
            timing: 3,
            delay: 0,
            a: '3000px',
            b: '-25px',
            c: '10px',
            d: '-5px',
          },
        })
      ),
    ]),
  ],
})
export class HomeComponent implements OnInit, AfterContentInit {
  @Input() themeType: string;
  public bounce: number = 1;
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);


  constructor() {
    setInterval(() => {
      this.bounce = this.bounce < 4 ? this.bounce + 1 : 1;
    }, 3000);
  }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    setTimeout(() => {
      const Parallax = require('parallax-js');
      const scene = document.getElementById('scene');
      const parallaxInstance1 = new Parallax(scene, {
        relativeInput: false,
      });
    }, 2000);
  }

  register() {
    // window.location.href = 'https://asma.brokeronline.in/sign-up/';
    window.location.href = 'https://retailer.direct-buy.in';
  }

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }
  
}
