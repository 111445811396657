import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ScrollSpyService } from '../../../components/scroll-spy/scroll-spy.service';
import { Config } from '../../../app-config';
import { Location } from '@angular/common';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Router } from "@angular/router";
// import { ScrollSpyService } from "../../components/scroll-spy/scroll-spy.service";
// import { Config } from "../../app-config"; 

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('collapsedCard', [
      state('collapsed, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('expanded',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        })
      ),
      transition('collapsed <=> expanded', [
        animate('400ms ease-in-out')
      ])
    ])
  ]
  
})
export class MainComponent implements OnInit {
  public currentSection;
  public themeConfig: any;
  public windowWidth: number;

  public openClass: string = '';
  public contentClass: string = 'content';

  public mobileHeaderClass: string = 'mobile-header-1';
  public desktopHeaderClass: string = 'desktop-header-1';
  // public horizontalNavClass: string = 'navbar-dark';

  public desktopLogo = 'assets/images/logo/transparent-1000px.png';

  public collapsedCard: string = 'collapsed';


  constructor(public scrollSpy: ScrollSpyService, private activatedRoute: ActivatedRoute, private location: Location, private _router: Router ) {
    
  this.themeConfig = Config.config;
  this.windowWidth = window.innerWidth;
  // console.log(this.themeConfig);
  } 


    //  }

  ngOnInit(): void {

    this.scrollSpy.count.subscribe(c => {
      this.currentSection = c;
    });
    document.querySelector('body').classList.add(this.themeConfig.themeType);
    switch (this.themeConfig.theme) {
      case 'vertical':
        this.mobileHeaderClass = 'mobile-header-1';
        this.desktopHeaderClass = 'desktop-header-1';
        switch (this.themeConfig.themeType) {
          case 'light':
              this.desktopLogo = 'assets/images/logo_new.png';
            break;
          default:
            this.desktopLogo = 'assets/images/logo_new.png';
        }
        break;
      }
      this.mobileHeaderClass = this.mobileHeaderClass + ' ' + this.themeConfig.themeType;
      this.desktopHeaderClass = this.desktopHeaderClass + ' ' + this.themeConfig.themeType;
  
      if (this.windowWidth > 991) {
        this.collapsedCard = 'false';
      }

  }
  onSectionChange(sectionId: any) {
    this.currentSection = sectionId;
    this.scrollSpy.nextCount(sectionId);
  }
  onResize(e) {
    if (this.windowWidth > 991) {
      this.collapsedCard = 'false';
    } else {
      this.collapsedCard = 'collapsed';
    }
  }

  scrollTo(section) {
    this.currentSection = section;
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }

  collapsedCardToggle() {
    this.collapsedCard = this.collapsedCard === 'collapsed' ? 'expanded' : 'collapsed';
  }
}
