<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">We Walk The Journey with You.</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-12">

      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp block">
          <div class="content">
            <!-- <span class="time">2019 - Present</span> -->
            <h3 class="title">Register</h3>
            <p>Fill in a simple registration form.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp block" data-wow-delay="0.2s">
          <div class="content">
            <!-- <span class="time">2017 - 2013</span> -->
            <h3 class="title">Setup</h3>
            <p>Work with our Customer Success Team to setup your store.</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp block" data-wow-delay="0.4s">
          <div class="content">
            <!-- <span class="time">2013 - 2009</span> -->
            <h3 class="title">Design</h3>
            <p>Choose how your store should look for your customers. Make it unique!</p>
          </div>
        </div>

            <!-- timeline item -->
            <div class="timeline-container wow fadeInUp block">
              <div class="content">
                <!-- <span class="time">2019 - Present</span> -->
                <h3 class="title">Setup Payment</h3>
                <p>Configure your payment solution using simple integrations.</p>
              </div>
            </div>
    
            <!-- timeline item -->
            <div class="timeline-container wow fadeInUp block" data-wow-delay="0.2s">
              <div class="content">
                <!-- <span class="time">2017 - 2013</span> -->
                <h3 class="title">Start Selling</h3>
                <p>Share the link. Publish it, promote it.</p>
              </div>
            </div>

        <!-- main line -->
        <span class="line"></span>

      </div>

    <!-- </div> -->

    <!-- <div class="col-md-6"> -->

      <!-- responsive spacer -->
      <!-- <div class="spacer-30 d-md-none d-lg-none"></div> -->

      <!-- timeline wrapper -->
      <!-- <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden"> -->

        <!-- timeline item -->
        <!-- <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2019 - Present</span>
            <h3 class="title">Setup Payment</h3>
            <p>Configure your payment solution using simple integrations.</p>
          </div>
        </div> -->

        <!-- timeline item -->
        <!-- <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2017 - 2013</span>
            <h3 class="title">Start Selling</h3>
            <p>Share the link. Publish it, promote it.</p>
          </div>
        </div> -->

        <!-- timeline item -->
        <!-- <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2013 - 2009</span>
            <h3 class="title">Back-End Developer</h3>
            <p>Lorem ipsum dolor sit amet quo ei simul congue exerci ad nec admodum perfecto.</p>
          </div>
        </div> -->

        <!-- main line -->
        <!-- <span class="line"></span> -->

      <!-- </div> -->

    <!-- </div> -->

  </div>

</div>
