<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">All about Direct-Buy</h2>

  <div class="spacer-60"></div>

  <!-- portfolio filter (desktop) -->
  <!-- <ul class="portfolio-filter list-inline wow fadeInUp">
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'all'}" (click)="workFilter = 'all'">Everything</li> -->
    <!-- <li class="list-inline-item" [ngClass]="{'current': workFilter === 'creative'}" (click)="workFilter = 'creative'">Creative</li>
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'art'}" (click)="workFilter = 'art'">Art</li>
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'design'}" (click)="workFilter = 'design'">Design</li>
    <li class="list-inline-item" [ngClass]="{'current': workFilter === 'branding'}" (click)="workFilter = 'branding'">Branding</li> -->
  <!-- </ul> -->

  <!-- portfolio filter (mobile) -->
  <!-- <div class="pf-filter-wrapper">
    <select class="portfolio-filter-mobile" (change)="onChange($event)">
      <option value="all">Everything</option>
      <option value="creative">Creative</option>
      <option value="art">Art</option>
      <option value="design">Design</option>
      <option value="branding">Branding</option>
    </select>
  </div> -->

  <!-- portolio wrapper -->
  <div class="row portfolio-wrapper">


    <!-- How to Video Section -->
    <div class="col-md-4 col-sm-6 grid-item branding" *ngIf="workFilter === 'all' || workFilter === 'branding'">
      <a href="javascript:" class="work-video">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term"> How to use Direct-Buy</span>
            <h4 class="title">How to Sign-Up and use Direct-Buy</h4>
            <span class="more-button"><i class="icon-camrecorder"></i></span>
          </div>
          <div class="thumb">
            <ng-image-slider [images]="videoObject" #nav autoplay="1"></ng-image-slider>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>


       <!-- Carousel Block -->
       <div class="col-md-4 col-sm-6 grid-item art branding" *ngIf="workFilter === 'all' || workFilter === 'art' || workFilter === 'branding'">
        <a href="javascript:" class="gallery-link" (click)="albumsGallery.open(0)">
          <div class="portfolio-item rounded shadow-dark">
            <div class="details">
              <span class="term">Product Peek</span>
              <h4 class="title">Easy to use product and order management</h4>
              <span class="more-button"><i class="icon-picture"></i></span>
            </div>
            <div class="thumb">
              <app-gallery #albumsGallery [albums]="albums"></app-gallery>
              <div class="mask"></div>
            </div>
          </div>
        </a>
      </div>

    <!-- Product Inventory Block -->
    <div class="col-md-4 col-sm-6 grid-item art" *ngIf="workFilter === 'all' || workFilter === 'art'">
      <!-- <a href="javascript:" class="work-image" (click)="singleGallery.open(0)"> -->
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">No Commission</span>
            <h4 class="title">Enjoy commission free payments</h4>
            <span class="more-button"><i class="fas fa-rupee-sign"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/insights/product_inventory.png" alt="Portfolio-title" />
            <!-- <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery> -->
            <div class="mask"></div>
          </div>
        </div>
      <!-- </a> -->
    </div>


      <!-- Subscription Block -->
      <div class="col-md-4 col-sm-6 grid-item creative" *ngIf="workFilter === 'all' || workFilter === 'creative'">
        <!-- <a href="javascript:" class="work-video" (click)="audioModal.show()"> -->
          <div class="portfolio-item rounded shadow-dark">
            <div class="details">
              <span class="term">Your Store</span>
              <h4 class="title">Import from our database or create your own</h4>
              <span class="more-button"><i class="icon-magnifier-add"></i></span>
            </div>
            <div class="thumb">
              <img src="assets/images/insights/onboarding_motivation.png" alt="Portfolio-title" />
              <div class="mask"></div>
            </div>
          </div>
        <!-- </a> -->
      </div>
  

    <!-- portfolio item -->
    <div class="col-md-4 col-sm-6 grid-item creative design" *ngIf="workFilter === 'all' || workFilter === 'creative' || workFilter === 'design'">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="exampleModalCenter.show()">
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">WhatsApp</span>
            <h4 class="title"> Send your store to customers</h4>
            <span class="more-button"><i class="fab fa-whatsapp"></i></span>
          </div>
          <div class="thumb">
            <!-- <img src="assets/images/works/whatsapp2.png" alt="Portfolio-title" />
             -->
             <app-gallery #singleGallery [albums]="singleGallery1"></app-gallery>
            <div class="mask"></div>
          </div>
        </div>
      </a>
    </div>

    <app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true" [hidden]="modal">
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <a href="javascript:" type="button" class="close " (click)="close()" data-dismiss="modal">&times;</a>
          <img src="assets/images/insights/whatsapp_thumb.png" alt="Title" />
          <h2>WhatsApp</h2>
          <p>"Want to know how Direct-Buy can help you take your business online.</p>
            <p>Or have any questions related to using the product.</p>
            <p>Or have any questions related to Direct-Buy</p>
            <p>Send us a WhatsApp Messenger. Click the WhatsApp Us button below."</p>
          <a href="https://wa.me/916362366933" target="_blank" class="btn btn-default">WhatsApp Us</a>
        </div>
        
      </div>
    </app-ui-modal>

    

  
    <!-- <app-ui-modal #audioModal dialogClass="modal-dialog-centered work-modal audio-modal" [hideHeader]="true" [hideFooter]="true">
      <div class="app-modal-body">
        <iframe class="mfp-iframe" frameborder="0" allowfullscreen="" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/240233494&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
      </div>
    </app-ui-modal> -->

 

    <!-- portfolio item -->
    <div class="col-md-4 col-sm-6 grid-item creative design" *ngIf="workFilter === 'all' || workFilter === 'creative' || workFilter === 'design'">
      <a href="javascript:" class="work-content" data-toggle="modal" (click)="CominsoonModalCenter.show()">
      <!-- <a href="javascript:" target="_blank"> -->
        
        <div class="portfolio-item rounded shadow-dark">
          <div class="details">
            <span class="term">Integrations help ease of doing business</span>
            <h4 class="title">Payment, SMS, Phone and a lot more...</h4>
            <span class="more-button"><i class="icon-link"></i></span>
          </div>
          <div class="thumb">
            <img src="assets/images/insights/integrations.png" alt="Portfolio-title" />
            <div class="mask"></div>
          </div>
        </div>
      <!-- </a> -->
      </a>
    </div>


    <app-ui-modal #CominsoonModalCenter dialogClass="modal-dialog-centered work-modal " [hideHeader]="true" [hideFooter]="true"  >
      <div class="app-modal-body">
        <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
          <a type="button" class="close " (click)="close()"  data-dismiss="modal" aria-hidden="true">&times;</a>
          <h2>Coming Soon</h2>
        </div>
      </div>
    </app-ui-modal>

  </div>

  <!-- more button -->
  <!-- <div class="load-more text-center mt-4">
    <a href="javascript:" class="btn btn-default"><i class="fas fa-spinner"></i> Load more</a> -->
    <!-- numbered pagination (hidden for infinite scroll) -->
    <!-- <ul class="portfolio-pagination list-inline d-none">
      <li class="list-inline-item">1</li>
      <li class="list-inline-item"><a href="javascript:">2</a></li>
    </ul>
  </div> -->

</div>
