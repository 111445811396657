<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeIn">Pricing Plans</h2>

  <div class="spacer-60"></div>

  <div class="row">

    <div class="col-md-4 pr-md-0 mt-md-4 mt-0">
      <!-- price item -->
      <div class="price-item bg-white rounded shadow-dark text-center  best">
        <img src="assets/images/bolby/price-1.svg" alt="Regular" />
        <h2 class="plan">Free</h2>
        <p>Fully Functional Store for a period of 3 month's.</p>
        <!-- <p>Self Managed Inventory</p> -->
        <h3 class="price"><em>₹</em>0<span>/3 month's</span></h3>
        <a href="javascript:" class="btn btn-default" (click)="register()">Register</a>
      </div>
    </div>

    <div class="col-md-4 pr-md-0 mt-md-4 mt-0">
      <!-- price item recommended-->
      <div class="price-item bg-white rounded shadow-dark text-center best">
        <!-- <span class="badge">Single Shop</span> -->
        <img src="assets/images/bolby/price-2.svg" alt="Premium" />
        <h2 class="plan">Single Shop</h2>
        <p>Fully Functional Store for a period of 1 year.</p>
        <!-- <p>Free Hosting</p>
        <p>40MB of storage space</p> -->
        <h3 class="price"><em>₹</em>4999<span>/year</span></h3>
        <a href="javascript:" class="btn btn-default" (click)="register()">Register</a>
      </div>
    </div>

    <div class="col-md-4 pr-md-0 mt-md-4 mt-0">
      <!-- price item -->
      <div class="price-item bg-white rounded shadow-dark text-center  best">
        <!-- <img src="assets/images/price-3.svg" alt="Ultimate" /> -->
        <i class="far fa-window-restore"></i>
        <h2 class="plan">Multi Store</h2>
        <p>Have more than one stores, contact us to understand how to manage all stores</p>
        <!-- <p>24/7 support</p>
        <h3 class="price"><em>$</em>99<span>Month</span></h3> -->
        <a href="javascript:" class="btn btn-default" (click)="scrollTo('contact')" >Contact Us</a>
      </div>
    </div>

  </div>

</div>
