import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
// import { Router } from '@angular/router';
// import { fuseAnimations } from '@fuse/animations';
// import { FuseAlertType } from '@fuse/components/alert';
// import { AuthService } from 'app/core/auth/auth.service';
// import { CreateShopService } from 'app/modules/admin/store/create-shop/create-shop.service';

declare var $: any;

@Component({
    selector: 'auth-terms',
    templateUrl: './terms.component.html',
    styleUrls: ['./terms.component.scss']

})
export class TermsComponent implements OnInit {

    /**
     * On init
     */
    ngOnInit(): void {

    }

    goback() {

    }



}
