<header *ngIf="this.themeConfig.theme !== 'horizontal'" class="{{ openClass }}" [ngClass]="mobileHeaderClass" (clickOutside)="openClass = ''">
    <div class="container">
      <!-- menu icon -->
      <div class="menu-icon d-inline-flex mr-4">
        <button (click)="openClass = (openClass === 'open') ? '' : 'open'">
          <span></span>
        </button>
      </div>
      <!-- logo image -->
    <div class="site-logo mobile_logo">
      <a routerLink="/">
        <img [src]="this.themeConfig.themeType === 'light' ? 'assets/images/logo/faviconlogo.png' : 'assets/images/logo/faviconlogo.png'" alt="Direct-Buy" />
      </a>
    </div>
    </div>
  </header>
  
  <header *ngIf="this.themeConfig.theme !== 'horizontal'" class="d-flex align-items-start flex-column {{ openClass }}" [ngClass]="desktopHeaderClass">
  
    <!-- logo image -->
    <div class="site-logo">
      <a routerLink="/">
        <img [src]="desktopLogo" alt="Direct-Buy" />
      </a>
    </div>
  
    <!-- main menu -->
    <nav>
      <ul class="vertical-menu scrollspy">
        <li [ngClass]="{'active': currentSection === 'home'}" (click)="scrollTo('home')">
          <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'home'}"><i class="icon-home"></i>
            <span *ngIf="this.themeConfig.theme !== 'collapsed'">Home</span>
          </a>
        </li>
        <li [ngClass]="{'active': currentSection === 'about'}" (click)="scrollTo('about')" >
          <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'about'}"><i class="icon-user-following"></i>
            <span *ngIf="this.themeConfig.theme !== 'collapsed'">About</span>
          </a>
        </li>
        <li [ngClass]="{'active': currentSection === 'services'}" (click)="scrollTo('services')">
          <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'services'}"><i class="icon-briefcase"></i>
            <span *ngIf="this.themeConfig.theme !== 'collapsed'">Services</span>
          </a>
        </li>
        <li [ngClass]="{'active': currentSection === 'works'}" (click)="scrollTo('works')">
          <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'works'}"><i class="icon-layers"></i>
            <span *ngIf="this.themeConfig.theme !== 'collapsed'">Insights</span>
          </a>
        </li>
        <li [ngClass]="{'active': currentSection === 'pricing'}" (click)="scrollTo('pricing')">
          <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'pricing'}"><i class="icon-credit-card"></i>
            <span *ngIf="this.themeConfig.theme !== 'collapsed'">Pricing</span>
          </a>
        </li>
        <li [ngClass]="{'active': currentSection === 'contact'}" (click)="scrollTo('contact')">
          <a href="javascript:" class="nav-link" [ngClass]="{'active': currentSection === 'contact'}"><i class="icon-bubbles"></i>
            <span *ngIf="this.themeConfig.theme !== 'collapsed'">Contact</span>
          </a>
        </li>
      </ul>
    </nav>
  
  </header>


  <!-- main layout -->
  <main class="{{ openClass }}" [ngClass]="contentClass" >

    <div id="parentDiv" class="main-content"  (sectionChange)="onSectionChange($event)">
        <section id="home" class="home d-flex align-items-center" [ngClass]="{'light': themeConfig.themeType === 'light'}">
          <app-home class="w-100" [themeType]="themeConfig.themeType"></app-home>
        </section>
        <section id="about">
          <app-about></app-about>
        </section>
        <section id="services">
          <app-services></app-services>
        </section>
        <section id="works">
          <app-works></app-works>
        </section>
        <section id="pricing">
          <app-pricing></app-pricing>
        </section>
        <section id="contact">
          <app-contact></app-contact>
        </section>
        <section id="footer">
          <app-footer></app-footer>
        </section>
      </div>

      
    <!-- <router-outlet></router-outlet> -->
  
    <!-- Go to top button -->
    <a href="javascript:" id="return-to-top" (click)="scrollTo('home')"><i class="fas fa-arrow-up"></i></a>
  </main>
  