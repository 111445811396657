  <!-- site footer -->
  <div class="footer rounded bg-white shadow-dark padding-30 mr-10 footer-space ">
    <div class="row">
      <div class="col-md-12">
        <p class="copyright">© 2022 <a href="https://arscnetworks.com/">ARSC Networks</a> Bangalore, India. | <a href="/terms">Terms & Conditions</a> | <a href="/privacy">Privacy Policy</a></p>
      </div>
      <div class="col-md-4"><span class="text-color"> <i class="fal fa-mobile-android"></i> <a href="tel:+918047162001">+918047162001</a></span>  </div>
      <div class="col-md-4" ><a href="https://api.whatsapp.com/send/?phone=916362366933&text&app_absent=0"><span><i class="fab fa-whatsapp"></i> +916362366933</span></a></div>
      <div class="col-md-4"><a href="mailto:info@direct-buy.in"><span><i class="far fa-envelope-open"></i> info@direct-buy.in</span></a></div>
    </div>

</div>


