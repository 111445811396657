<div class="container">

  <!-- section title -->
  <h2 class="section-title wow fadeInUp">About Direct-Buy</h2>

  <div class="spacer-30"></div>

  <div class="row">

    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <img class="imgae_hide" src="assets/images/logo-new_1.png" alt="Direct-Buy" />
      </div>
      <div class="spacer-10 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-6">
            <!-- about text -->
            <p>The Direct-Buy platform takes your local business online. With Direct-Buy, you get wider coverage, giving your customers the choice of buying from their favorite store, at their convenience.</p>
            <p>Contact us on  <a href="tel:+918047162001">+918047162001</a> to know more.</p>
            <div class="mt-3">
              <a href="javascript:" class="btn btn-default" (click)="register()">Register</a>
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <div class="col-md-6">
            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Direct-Buy Commission</h4>
                <span class="float-right">0%</span>
              </div>
              <ngb-progressbar type="warning" [value]="0"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Payment Remittance</h4>
                <span class="float-right">100%</span>
              </div>
              <ngb-progressbar type="success" [value]="100"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <!-- skill item -->
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Ease of Use</h4>
                <span class="float-right">50%</span>
              </div>
              <ngb-progressbar type="warning" [value]="50"></ngb-progressbar>
            </div>
       
            <h6 class="text-style">"Your inputs will help us make direct-buy easy to use"</h6>

          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- row end -->

  <div class="spacer-10"></div>

  <!-- <div class="row">
    <div class="col-md-3 col-sm-6"> -->
      <!-- fact item -->
      <!-- <div class="fact-item">
        <span class="icon icon-fire"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">198</em></h3>
          <p class="mb-0">Projects completed</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6"> -->
      <!-- fact item -->
      <!-- <div class="fact-item">
        <span class="icon icon-cup"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">5670</em></h3>
          <p class="mb-0">Cup of coffee</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6"> -->
      <!-- fact item -->
      <!-- <div class="fact-item">
        <span class="icon icon-people"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">427</em></h3>
          <p class="mb-0">Satisfied clients</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6"> -->
      <!-- fact item -->
      <!-- <div class="fact-item">
        <span class="icon icon-badge"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">35</em></h3>
          <p class="mb-0">Nominees winner</p>
        </div>
      </div>
    </div>

  </div> -->

</div>
