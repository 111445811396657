import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { LayoutComponent } from './theme/layout/layout.component';
import { ShopsComponent } from './components/sections/shops/shops.component';
// import { CategoriesComponent } from './components/sections/categories/categories.component';
import { TermsComponent } from './components/sections/terms/terms.component';
import { PrivacyComponent } from './components/sections/privacy/privacy.component';
import { MainComponent } from './components/sections/main/main.component';

const routes: Routes = [
  
  // {
  //   path: '',
  //   redirectTo: '/main',
  //   pathMatch: 'full',
   
  // },
  // {
  //   path: 'main',
  //   component: MainComponent,
  // },
  
  
  
  {
    path: '',
    redirectTo: '/theme/view/vertical/light',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    component: LandingComponent,
  },
 
  {
    path: 'theme',
    component: LayoutComponent,
    children: [
      {
        path: 'view/:layout/:type',
        loadChildren: () =>
          import('./view/view.module').then((module) => module.ViewModule),
          
      }
    ],
  },
  {
    path: 'terms', component: TermsComponent,
    
  },
  {
    path: 'privacy', component: PrivacyComponent,
    
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
