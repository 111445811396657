import { Component, OnInit, ViewChild, Input } from '@angular/core';
// import * as $ from "jquery";
// import { Router } from "@angular/router";
declare var $ : any;

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})



export class WorksComponent implements OnInit {
  // @ViewChild('closebutton') closebutton;
  // @ViewChild('myModalClose') modalClose;
  @Input() videoAutoplay: true;
  @Input() showVideoControls: false;
  public workFilter: string;
  public singleGallery1: any;
  public albums: any = [];
  public popup: any=[];
  public ModalCenter: any=[];
  

  videoObject: Array<object> = [
    {
      video: 'assets/images/video/websitevideo.mp4',
      allow:'autoplay',
      posterImage: 'assets/images/insights/video_image.png',
     
    }
    
  ];
  
  modal: boolean;
  
 

  constructor() {
    this.workFilter = 'all';
    this.singleGallery1 = [
      {
        src: 'assets/images/insights/whatsapp.png',
        caption: 'Project Management Illustration',
        thumb: 'assets/images/insights/whatsapp.png'
      }
    ];



    const imageMap = new Map([
      [1, "Success Message"],
      [2, "Payment Options"],
      [3, "Orders"],
      [4, "Create Shop"],
      [5, "Sign In"],
    ]);
// console.log(imageMap.get(1));

    for (let i = 5, j = 1; i >= 1; i--, j++) {
      const album = {
        src: 'assets/images/insights/carousel/' + i + '.png',
        caption: imageMap.get(i) ,
        thumb: 'assets/images/insights/carousel_image.png',
        // thumb: 'assets/images/insights/' + i + '.png',
      };

      this.albums.push(album);
    }

    this.popup = [
      {
        // src: 'assets/images/works/carousel/store.png',
        caption: 'Project Management Illustration'
        // thumb: 'assets/images/works/carousel/store.png'
      }
    ];
  }

  ngOnInit(): void {
  }

  onChange (e) {
    this.workFilter = e.target.value;
  }

  close() {
    // $("#CominsoonModalCenter").modal("hide");
    this.modal=true;
    setTimeout(()=>{
      window.location.reload();
    }, 1);
}
}
