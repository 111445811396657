<div class="container terms">
    <!-- <section> -->
        <h2><strong>Terms and Privacy</strong></h2>

        <p>Welcome to direct-buy!</p>
        <div class="card">
            <div class="card-body">
               <a href="https://direct-buy.in/privacy.html"><span><i class="fas fa-long-arrow-alt-right"></i> <strong>Privacy Policy</strong></span></a> 
            </div>
        </div>

        &nbsp;&nbsp;
        <p>
            THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
            (hereinafter “You” or “Your”) AND DIRECT-BUY (hereinafter
            “platform”) A PLATFORM BY ARSC NETWORKS. (hereinafter “ARSC”)
            GOVERNING YOUR USE OF DIRECT-BUY APPLICATION AND WEBSITE SOFTWARE.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Parts of this Agreement</strong></h3>

        <p>
            This Agreement consists of the General Terms and Conditions and
            Privacy Policy for use of the platform collectively referred to as
            the “Terms”.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Acceptance of the Terms</strong></h3>

        <p>
            By Signing up with the platform you agree that you are of legal age
            to enter into a binding agreement in order to accept the Terms. If
            you do not agree to the Terms, do not use any of our Services. You
            can accept the Terms by checking a checkbox indicating your
            acceptance of the terms or by actually using the Services.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Description of Service</strong></h3>

        <p>
            ARSC provide the service via the platform that as a platform enables
            to list products, perform transactions, interact with customers,
            integrate with other platforms and products, display of
            advertisements and offers. You may use the Services for your
            business use only. You may connect to the Services using any
            Internet browser supported by the Services. You are responsible for
            obtaining access to the Internet and the equipment necessary to use
            the Services. You can create and edit content with your user account
            and if you choose to do so, you can publish and share such content.
            You may not connect and use the services offered by the platform to
            copy, infringe or violate the functioning of the platform, its
            products, its customers or any other services offered by the
            platform and ARSC
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Subscription to Beta Service</strong></h3>

        <p>
            We may offer certain Services as closed or open beta services ("Beta
            Service" or “Beta Services”) for the purpose of testing and
            evaluation. You agree that we have the sole authority and discretion
            to determine the period of time for testing and evaluation of Beta
            Services. We will be the sole judge of the success of such testing
            and the decision, if any, to offer the Beta Services as commercial
            services. You will be under no obligation to acquire a subscription
            to use any paid Service as a result of your subscription to any Beta
            Service. We reserve the right to fully or partially discontinue, at
            any time and from time to time, temporarily or permanently, any of
            the Beta Services with or without notice to you. You agree that ARSC
            will not be liable to you or to any third party for any harm related
            to, arising out of, or caused by the modification, suspension or
            discontinuance of any of the Beta Services for any reason.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Modification of Terms of Service</strong></h3>

        <p>
            We may modify the Terms upon notice to you at any time through a
            service announcement or by sending email to your primary email
            address. If we make significant changes to the Terms that affect
            your rights, you will be provided with at least 30 days advance
            notice of the changes by email to your primary email address or via
            notification with the platform and/or other medium that may be used
            for communication. You may terminate your use of the Services by
            providing ARSC notice by email or by using the delete store/account
            options within the platform within 30 days of being notified of the
            availability of the modified Terms if the Terms are modified in a
            manner that substantially affects your rights in connection with use
            of the Services. In the event of such termination, you will not be
            entitled to any refunds of the unused portion of any prepaid fees.
            Your continued use of the Service after the effective date of any
            change to the Terms will be deemed to be your agreement to the
            modified Terms.
        </p>
    <!-- </section> -->
    <!-- <section> -->
        <h3><strong>User Sign up Obligations</strong></h3>

        <p>
            You need to sign up for a user account by providing all required
            information in order to access or use the Services. You agree to: a)
            provide true, accurate, current and complete information about
            yourself as prompted by the sign up process; and b) maintain and
            promptly update the information provided during sign up to keep it
            true, accurate, current, and complete. If you provide any
            information that is untrue, inaccurate, outdated, or incomplete, or
            if ARSC has reasonable grounds to suspect that such information is
            untrue, inaccurate, outdated, or incomplete, ARSC may terminate your
            user account and refuse current or future use of any or all the
            Services.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Platform Accounts and Administrators</strong></h3>

        <p>
            When you sign up for an account for your platform you may specify
            one or more administrators. The administrators will have the right
            to configure the Services based on your requirements and manage end
            users in your platform account. You are responsible for i) ensuring
            confidentiality of your platform account password, ii) appointing
            competent individuals as administrators for managing your platform
            account, and iii) ensuring that all activities that occur in
            connection with your platform account comply with this Agreement.
            You understand that ARSC is not responsible for account
            administration and internal management of the Services for you. You
            are responsible for taking necessary steps for ensuring that your
            platform does not lose control of the administrator accounts. You
            agree not to hold ARSC liable for the consequences of any action
            taken by ARSC in good faith in this regard.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Platform usage </strong></h3>

        <p></p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong></strong></h3>

        <p>
            By signing up with the platform, you agree to use the platform for
            its intended purpose, i.e., creating an online store for your
            business. In doing so, you agree to follow the guidelines provided
            in the dos and don’ts section of this agreement Any violation of the
            platform usage may lead to termination of the service and legal
            action binding to the jurisdiction of the High Court of Karnataka.
            ARSC is not legally binding for the information of products, images,
            content etc. you have uploaded on your portal, and you affirm that
            the content is legal to be published on your store and any disputes
            arising out of the same is legally implied to you and not ARSC
            Networks or Direct-Buy.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Personal Information and Privacy</strong></h3>

        <p>
            Personal information you provide to ARSC through the Service is
            governed by Direct-Buy’s Privacy Policy. Your election to use the
            Service indicates your acceptance of the terms of the ARSC Networks
            Policy. You are responsible for maintaining confidentiality of your
            username, password and other sensitive information. You are
            responsible for all activities that occur in your user account and
            you agree to inform us immediately of any unauthorized use of your
            user account by email to contactus@arscnetworks.com or by calling us
            on any of the numbers listed on https://direct-buy.in. We are not
            responsible for any loss or damage to you or to any third party
            incurred as a result of any unauthorized access and/or use of your
            user account, or otherwise.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Communications from Platform</strong></h3>

        <p>
            The Service may include certain communications from Direct-Buy, such
            as service announcements, administrative messages, newsletters, new
            product and service launches and/or advertisement and other
            collaboration information. You understand that these communications
            shall be considered part of using the Services. As part of our
            policy to provide you total privacy, we also provide you the option
            of opting out from receiving newsletters from us. However, you will
            not be able to opt-out from receiving service announcements and
            administrative messages, new product and service launches and/or
            advertisement and other collaboration information.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Complaints</strong></h3>

        <p>
            You agree that you own responsibility of service to your customers
            and if there is any complaint from any person against you with
            respect to your activities as part of use of the Services (other
            than where the grievance redressal mechanism provided under the head
            "Grievance Redressal" applies), we will forward the complaint to the
            primary email address of your user account. You must respond to the
            complainant directly with the person to resolve the issue.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Commitment to Service</strong></h3>

        <p>
            You agree that you own responsibility of service for your customers
            and also commit to uphold the brand image of Direct-Buy. We are very
            committed to providing our services and enhancing them for your
            benefits. We expect the same to adhered by you. At any time, if ARSC
            feels the Terms are violated or there is an adverse impact to our
            brand or reputation of the platform and ARSC, ARSC keeps the right
            to deny service to you and update the platform with the information
            regarding the reason for denial of service.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Fees and Payments</strong></h3>

        <p>
            The Services are available under subscription plans of various
            durations. Your subscription will be automatically renewed at the
            end of each subscription period unless you wish to cancel your paid
            subscription or inform us that you do not wish to renew the
            subscription. At the time of automatic renewal, the subscription fee
            will be charged to the Card last used by you. We provide you the
            option of changing the details if you would like the payment for the
            renewal to be made through a different Card. If you do not wish to
            renew the subscription, you must inform us at least seven days prior
            to the renewal date. If you have not cancelled and/or if you have
            not informed us that you do not wish to renew the subscription, you
            will be presumed to have authorized ARSC to charge the subscription
            fee to the Card last used by you. You will not be charged for using
            any Service unless you have opted for a paid subscription plan.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Restrictions on Use</strong></h3>

        <p>
            In addition to all other terms and conditions of this Agreement, you
            shall not: (i) transfer the Services or otherwise make it available
            to any third party; (ii) provide any service based on the Services
            without prior written permission; (iii) use the third party links to
            sites without agreeing to their website terms & conditions; (iv)
            post links to third party sites or use their logo, company name,
            etc. without their prior written permission; (v) host, display,
            upload, modify, publish, transmit, store, update or share any
            information that belongs to another person and to which you do not
            have any right, including personal or confidential information of
            any person or entity without obtaining consent or permission from
            such person or entity; (vi) use the Services in any manner that
            could damage, disable, overburden, impair or harm any server,
            network, computer system, resource of ARSC; (vii) violate any
            applicable local, state, national or international law; (viii) use
            our Services in any manner that threatens the unity, integrity,
            defence, security or sovereignty of India, friendly relations of
            India with other countries, or public order, or causes incitement to
            the commission of any cognisable offence or prevents investigation
            of any offence or is insulting other countries; (ix) create a false
            identity to mislead any person as to the identity or origin of any
            communication; (x) use the services for transmitting information
            that is patently false and untrue, and is written or published in
            any form, with the intent to mislead or harass a person, entity or
            agency for financial gain or to cause any injury to any person; or
            (xi) use the services in a manner that relates to or encourages any
            activity prohibited by law in India.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>No Illegal Activities</strong></h3>

        <p>
            You agree to be solely responsible for the contents of your
            transmissions through the Services. You agree not to use the
            Services for illegal purposes or for the transmission of material
            that is unlawful, defamatory, insulting, harassing, libelous,
            invasive of another's privacy (including bodily privacy), abusive,
            threatening, harmful, vulgar, pornographic, paedophilic, harmful to
            children, obscene, racially or ethnically objectionable, or is
            otherwise objectionable, offends religious sentiments, promotes
            racism, contains viruses or malicious code, or that which infringes
            or may infringe intellectual property or other rights of another.
            You agree not to use the Services for the transmission of "junk
            mail", "spam", "chain letters", “phishing” or unsolicited mass
            distribution of email. We reserve the right to terminate your access
            to the Services if there are reasonable grounds to believe that you
            have used the Services for any illegal or unauthorized activity.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Grievance Redressal</strong></h3>

        <p>
            In case of any grievance, the term as defined under Information
            Technology (Intermediary Guidelines and Digital Media Ethics Code)
            Rules 2021, our Grievance Officer, can be contacted by sending an
            email to contactus@arscnetworks.com.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Inactive User Accounts Policy</strong></h3>

        <p>
            We reserve the right to terminate unpaid user accounts that are
            inactive for a continuous period of 120 days. In the event of such
            termination, all data associated with such user account will be
            deleted. We will provide you prior notice of such termination and
            option to back-up your data. The data deletion policy may be
            implemented with respect to any or all of the Services. Each Service
            will be considered an independent and separate service for the
            purpose of calculating the period of inactivity. In other words,
            activity in one of the Services is not sufficient to keep your user
            account in another Service active. In case of accounts with more
            than one user, if at least one of the users is active, the account
            will not be considered inactive.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Data Ownership</strong></h3>

        <p>
            We respect your right to ownership of content created or stored by
            you. You own the content created or stored by you. Unless
            specifically permitted by you, your use of the Services does not
            grant ARSC the license to use, reproduce, adapt, modify, publish or
            distribute the content created by you or stored in your user account
            for ARSC’s commercial, marketing or any similar purpose. But you
            grant ARSC permission to access, copy, distribute, store, transmit,
            reformat, publicly display and publicly perform the content of your
            user account solely as required for the purpose of providing the
            Services to you and other customers of ARSC.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Trademark</strong></h3>

        <p>
            DIRECT-BUY, ARSC NETWORKS logo, the names of individual Services and
            their logos are trademarks of ARSC Networks. You agree not to
            display or use, in any manner, the ARSC Networks trademarks, without
            ARSC’s prior permission.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Disclaimer of Warranties </strong></h3>

        <p>
            YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS
            AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN
            AS-IS-AND-AS-AVAILABLE BASIS. ARSC EXPRESSLY DISCLAIMS ALL
            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
            NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE. ARSC MAKES NO WARRANTY THAT THE
            SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE. USE
            OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE
            SERVICES SHALL BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, MOBILE
            TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF THE
            SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
            INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM ARSC, ITS
            EMPLOYEES OR REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT EXPRESSLY
            STATED IN THE TERMS.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Limitation of Liability</strong></h3>

        <p>
            YOU AGREE THAT ARSC SHALL, IN NO EVENT, BE LIABLE FOR ANY
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER
            LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS
            INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR
            OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR USE OF OR INABILITY TO
            USE THE SERVICE, EVEN IF ARSC HAS BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGE. IN NO EVENT SHALL ARSC’S ENTIRE LIABILITY TO YOU IN
            RESPECT OF ANY SERVICE, WHETHER DIRECT OR INDIRECT, EXCEED THE FEES
            PAID BY YOU TOWARDS SUCH SERVICE.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Indemnification</strong></h3>

        <p>
            You agree to indemnify and hold harmless ARSC, its officers,
            directors, employees, suppliers, and affiliates, from and against
            any losses, damages, fines and expenses (including attorney's fees
            and costs) arising out of or relating to any claims that you have
            used the Services in violation of another party's rights, in
            violation of any law, in violations of any provisions of the Terms,
            or any other claim related to your use of the Services, except where
            such use is authorized by ARSC.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Governing law and Jurisdiction</strong></h3>

        <p>
            Any controversy or claim arising out of or relating to the Terms
            shall be settled and adjudicated exclusively by the courts of
            Bangalore in accordance with the laws of India without regard to
            conflict of law principles.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>Suspension and Termination</strong></h3>

        <p>
            We may suspend your user account or temporarily disable access to
            whole or part of any Service in the event of any suspected illegal
            activity, extended periods of inactivity or requests by law
            enforcement or other government agencies. Objections to suspension
            or disabling of user accounts should be made to
            contactus@arscnetworks.com within thirty days of being notified
            about the suspension. We may terminate a suspended or disabled user
            account after thirty days. We will also terminate your user account
            on your request. In addition, we reserve the right to terminate your
            user account and deny the Services upon reasonable belief that you
            have violated the Terms and to terminate your access to any Beta
            Service in case of unexpected technical issues or discontinuation of
            the Beta Service. You have the right to terminate your user account
            if ARSC breaches its obligations under these Terms and in such
            event, you will be entitled to prorated refund of any prepaid fees.
            Termination of user account will include denial of access to all
            Services, deletion of information in your user account such as your
            email address and password and deletion of all data in your user
            account.
        </p>
    <!-- </section> -->

    <!-- <section> -->
        <h3><strong>END OF TERMS OF SERVICE</strong></h3>

        <p>
            If you have any questions or concerns regarding this Agreement,
            please contact us at <a href="mailto:contactus@arscnetworks.com" >contactus@arscnetworks.com</a>.
        </p>
    <!-- </section> -->
    <!-- <section> -->
     

    <a href="https://direct-buy.in"
        ><button class="btn btn-default ">Go Back</button></a
    >
    <a href="javascript:void(0);" (click)="goback()" style="text-decoration: none">Go back</a>
</div>
