import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from './api-response';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  constructor(private http: HttpClient) {}

  baseUrl = 'http://localhost/api';

  contactForm(data): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl + '/contactForm.php', data);
  }
}
