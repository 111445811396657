import { Component, OnInit } from '@angular/core';
// import { Router } from "@angular/router";
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
 
  constructor() { }

  ngOnInit(): void {
    // if (this.isMobile()) {
    //   window.location.href = "https://m.direct-buy.in";
    // }
  }


  register() {
    // window.location.href = 'https://asma.brokeronline.in/sign-up/';
    window.location.href = 'https://retailer.direct-buy.in';
  }

}
